<template>
  <div>
    <table-list :columns="columns"
                :data="nodeList"
                fit
                stripe
                :tableOption="{size: 'mini'}"
                :pageData="pageData"
                :headData="headData"
                @handlePageChange="handlePageChange"
                @handleSizeChange="handleSizeChange"
                :cell-class-name="addClass"
                @cell-click="cellClick">
      <template #networkIP="{row}">
        <a>{{ row.networkIP }}</a>
      </template>
      <template #height="{row}">
        <span>{{ toThousands(row.height) || '无' }}</span>
      </template>
      <template #hash="{row}">
        <div class="div-row" v-if="row.hash">
          <popover :width="500"
                   :shard="row.shardName"
                   :value="row.hash"
                   :isSubStr="false"
                   :divClass="'shenglue-1'"></popover>
          <i class="el-icon-document-copy" style="margin-left:10px"
             @click="copy($event, row.hash)"></i>
        </div>
        <span v-else>无</span>
      </template>
      <template #version="{row}">
        <span>{{ row.version || '无' }}</span>
      </template>
      <template #keepLive="{row}">
        <span v-if="row.keepLive">在线</span>
        <span v-else>离线</span>
      </template>
    </table-list>
  </div>
</template>

<script>
import Clipboard from 'clipboard'
import TableList from '@/components/TableList'
import publicFunction from '@/utils/publicFunction.js'
import Popover from '@/components/Popover'
export default {
  props: {
    datas: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    datas: {
      handler() {
        // console.log('监听')
        this.getData(1)
      },
      deep: true,
      immediate: true
    }
  },
  components: {
    TableList,
    Popover
  },
  data() {
    return {
      nodeList: [],
      pageData: {
        size: 10, // 每页的数据量
        pageSize: 10, // 每页的条数跳转页面
        pageSizes: [10, 20, 50, 100], // 每页的条数跳转页面
        total: 0, // 总条数
        layout: 'prev, pager, next, total, sizes, jumper, slot',
        currentPage: 1 // 当前页
      }, // 分页配置
      headData: {
        title: '链端监控列表'
      },
      columns: [
        {
          label: '序号',
          type: 'index',
          show: true,
          align: 'center',
          width: 80
        },
        {
          label: '节点地址',
          prop: 'networkIP',
          width: 160,
          show: true,
          enableSlot: true
        },
        {
          label: '网络端口',
          prop: 'networkPort',
          show: true,
          align: 'center',
          width: 100
        },
        {
          label: '节点类型',
          prop: 'peerType',
          show: true,
          align: 'center',
          width: 100
        },
        {
          label: '分片名称',
          prop: 'peerName',
          show: true,
          align: 'center',
          width: 100
        },
        {
          label: '区块高度',
          prop: 'height',
          show: true,
          enableSlot: true,
          align: 'center',
          width: 100
        },
        {
          label: '区块哈希',
          prop: 'hash',
          show: true,
          enableSlot: true,
          minWidth: 120
        },
        {
          label: '节点状态',
          prop: 'keepLive',
          show: true,
          enableSlot: true,
          align: 'center',
          width: 100
        },
        {
          label: '版本号',
          prop: 'version',
          show: true,
          enableSlot: true,
          align: 'center',
          width: 100
        },
        {
          prop: 'operators',
          label: '操作',
          fixed: 'right',
          align: 'right',
          width: 120,
          children: [
            {
              icon: 'el-icon-view',
              label: '查看',
              clickEvent: (thisVue, row) => {
                thisVue.handleDetail(row)
              },
              showFilter: () => {
                return true
              }
            }
          ]
        }
      ]
    }
  },
  created() {
    // this.getData(1)
  },
  mounted() {
    var _this = this
    setTimeout(function () {
      _this.getData(_this.pageData.currentPage)
    }, 1000)
  },
  methods: {
    // 数字加逗号转化为金额类型
    toThousands(num) {
      return publicFunction.toThousands(num)
    },
    // 一键复制
    copy(e, text) {
      console.log(e, text)
      const clipboard = new Clipboard(e.target, { text: () => text })
      this.copyLoading = true
      clipboard.on('success', (e) => {
        // 判断消息提示条数
        if (document.getElementsByClassName('el-message').length > 2) return
        this.$message({ type: 'success', message: '复制成功' })
        // 释放内存
        clipboard.off('error')
        clipboard.off('success')
        clipboard.destroy()
      })
      clipboard.on('error', (e) => {
        // 不支持复制
        this.$message({ type: 'waning', message: '该浏览器不支持自动复制' })
        // 释放内存
        clipboard.off('error')
        clipboard.off('success')
        clipboard.destroy()
      })
      clipboard.onClick(e)
    },
    // 跳转详情页
    handleDetail(row) {
      if (row.keepLive) {
        let nodeList = []
        let obj = {}
        obj.ID = row.iD
        obj.Address = row.networkIP + ':' + row.networkPort
        obj.ip = row.networkIP
        obj.port = row.networkPort
        nodeList.push(obj)
        let addressList = JSON.stringify(nodeList)
        this.$router.push({
          name: 'nodeDetail',
          query: { addressList: addressList }
        })
      } else {
        this.$message.error('该节点已离线！')
      }
    },
    // 分页数据处理函数
    pageDataFn(page, data) {
      // 处于第几页 page
      // 保存每页数据的数组
      var pagedata = []
      // size 每页条数
      var size = this.pageData.size
      // 设置开始
      let start = size * page - size
      // 设置结束长度
      let end = size * page
      end = end > data.length ? data.length : end
      for (let i = start; i < end; i++) {
        // 所有分页数据 data
        pagedata.push(data[i])
      }
      return pagedata
    },
    getData(currentPage) {
      this.nodeList = this.pageDataFn(currentPage, this.datas)
      this.pageData.total = this.datas.length
      this.pageData.currentPage = currentPage
    },
    handlePageChange(goPage) {
      this.pageData.currentPage = goPage
      this.getData(goPage)
    },
    // 配置每页条数
    handleSizeChange(number) {
      this.pageData.currentPage = 1
      this.pageData.size = number
      this.pageData.pageSize = number
      this.getData(1)
    },
    // ip添加类
    addClass({ row, column, rowIndex, columnIndex }) {
      if (column.property === 'networkIP') {
        return 'networkIP'
      }
    },
    // 点击ip事件
    cellClick(row, column, cell, event) {
      if (column.property === 'networkIP') {
        this.$emit('on-details', row.networkIP)
      }
    }
  }
}
</script>
