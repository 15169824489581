var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('table-list',{attrs:{"columns":_vm.columns,"data":_vm.nodeList,"fit":"","stripe":"","tableOption":{size: 'mini'},"pageData":_vm.pageData,"headData":_vm.headData,"cell-class-name":_vm.addClass},on:{"handlePageChange":_vm.handlePageChange,"handleSizeChange":_vm.handleSizeChange,"cell-click":_vm.cellClick},scopedSlots:_vm._u([{key:"networkIP",fn:function(ref){
var row = ref.row;
return [_c('a',[_vm._v(_vm._s(row.networkIP))])]}},{key:"height",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.toThousands(row.height) || '无'))])]}},{key:"hash",fn:function(ref){
var row = ref.row;
return [(row.hash)?_c('div',{staticClass:"div-row"},[_c('popover',{attrs:{"width":500,"shard":row.shardName,"value":row.hash,"isSubStr":false,"divClass":'shenglue-1'}}),_c('i',{staticClass:"el-icon-document-copy",staticStyle:{"margin-left":"10px"},on:{"click":function($event){return _vm.copy($event, row.hash)}}})],1):_c('span',[_vm._v("无")])]}},{key:"version",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.version || '无'))])]}},{key:"keepLive",fn:function(ref){
var row = ref.row;
return [(row.keepLive)?_c('span',[_vm._v("在线")]):_c('span',[_vm._v("离线")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }