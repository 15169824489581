<template>
  <div class="ym-page">
    <!-- 顶部组件 -->
    <search-form :form="form"
                 @search="search"
                 @select="select"></search-form>

    <!-- 列表 -->
    <table-list :datas="list" @on-details="onDetails"></table-list>

  </div>
</template>

<script>
import TableList from './component/TableList.vue'
import SearchForm from './component/SearchForm'
import { getShardName, setShardName, setRefresh, getRefresh } from '@/utils/localStorage.js'
export default {
  components: {
    TableList,
    SearchForm
  },
  data() {
    return {
      list: [], // DNS的节点列表
      form: {
        shard: null, // 分片名
        keepLive: false, // 节点状态
        time: 5000 // 默认5秒刷新
      },
      timer: '' // 定时器
    }
  },
  created() {
    if (getShardName()) {
      this.form.shard = getShardName()
    }
  },
  mounted() {
    // 进入页面时刷新默认5秒
    this.getData()
    setTimeout(res => {
      this.getNode()
    }, 500)
    setRefresh(true)
    this.getInterval(this.form.time)
  },
  destroyed() {
    clearInterval(this.timer)
  },
  methods: {
    // 分片名切换刷新
    select(value) {
      setShardName(value)
    },
    search(form) {
      // 重置表单
      this.form = form
      // 搜索列表
      this.getData()
      // 刷新频率
      this.getInterval(this.form.time)
    },
    // 查看详情
    onDetails(networkIP) {
      // console.log('查看详情：', networkIP)
    },
    // 定时请求
    getInterval(time) {
      let that = this
      clearInterval(this.timer)
      this.timer = setInterval(function () {
        if (getRefresh()) {
          that.getNode()
        }
      }, time)
    },

    // 获取初始数据
    getData() {
      // 获取节点列表
      this.getNodeList().then((res) => {
        var list = res
        this.list = JSON.parse(JSON.stringify(list))
      })
    },

    // 获取节点列表
    getNodeList() {
      // console.log('测试:', this.value)
      let params = {
        peerName: this.form.shard,
        keepLive: this.form.keepLive
      }
      return this.getDNS(params)
    },

    // 请求DNS节点列表
    async getDNS(params) {
      let { code, data } = await this.$api.home.getNodes(params)
      if (code === 200) {
        return data
      } else {
        clearInterval(this.timer)
        return this.$message.error('请求节点列表失败！')
      }
    },

    // 请求节点信息
    getNode() {
      // 依次请求节点区块高度
      let temp = []
      for (let i = 0; i < this.list.length; i++) {
        // 当节点存活时
        if (this.list[i].keepLive) {
          // 配置请求信息
          let options = {
            json: JSON.stringify({
              mode: 'Chain',
              action: 'info'
            }),
            ip: this.list[i].networkIP,
            port: this.list[i].networkPort
          }
          temp.push(
            new Promise((resolve, reject) => {
              this.$api.home.nodeInfo(options).then((res1) => {
                // res1.data.error
                if (res1.code === 200) {
                  let _info = JSON.parse(res1.data.message)
                  // console.log('区块信息:', _info)
                  // 如果区块高度更新则改变区块高度
                  this.$set(this.list[i], 'height', _info.Chain.Height)
                  this.$set(this.list[i], 'hash', _info.Chain.Hash)
                  this.$set(this.list[i], 'version', _info.Version)
                  resolve(this.list[i])
                } else {
                  clearInterval(this.timer)
                }
              })
            })
          )
        }
        Promise.all(temp).then((res) => {
          // console.log(res)
        })
      }
    }
  }
}
</script>

<style lang='scss' scoped>
.form {
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #e4e7ed;
  margin-bottom: 12px;
}
::v-deep {
  .el-input__inner {
    // width: 180px !important;
  }
}
.head-title {
  font-size: 14px;
  color: #606266;
  line-height: 40px;
  margin-right: 16px;
}
.left{
  margin-left: 16px;
}
</style>
