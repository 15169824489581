<template>
  <div class="search-form">
    <el-form ref="form"
             :model="form"
             label-width="100px"
             label-position="right">
      <el-row style="width:100%">
        <el-col :xs="24"
                :sm="24"
                :md="8"
                :lg="8"
                :xl="8">
          <el-form-item class="inputWid"
                        label="分片名">
            <el-select class="inputWid"
                       @change="handleSelect"
                       @keyup.enter.native="confirm"
                       v-model="form.shard">
              <el-option v-for="item in options"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24"
                :sm="24"
                :md="8"
                :lg="8"
                :xl="8">
          <el-form-item class="inputWid"
                        label="节点状态">
            <el-select class="inputWid"
                       @keyup.enter.native="confirm"
                       v-model="form.keepLive">
              <el-option v-for="item in keepLiveOpt"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24"
                :sm="24"
                :md="8"
                :lg="8"
                :xl="8">
          <el-form-item class="inputWid"
                        label="刷新频率">
            <el-select class="inputWid"
                       @keyup.enter.native="confirm"
                       v-model="form.time">
              <el-option v-for="item in timeOpt"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        </el-row>
        <el-row style="width:100%">
        <el-col class="search-btn"
                :xs="24"
                :sm="24"
                :md="24"
                :lg="24"
                :xl="24">
          <el-form-item>
            <el-button type="primary"
                       @click="confirm">查询</el-button>
            <el-button class="form-item-Left"
                       @click="rest">重置</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    form: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      options: [],
      keepLiveOpt: [
        {
          value: false,
          label: '全部'
        },
        {
          value: true,
          label: '在线'
        }
      ],
      timeOpt: [
        {
          value: 1000,
          label: '1秒刷新'
        },
        {
          value: 5000,
          label: '5秒刷新'
        },
        {
          value: 10000,
          label: '10秒刷新'
        }
      ]
    }
  },
  mounted() {
    this.getAliases()
  },
  methods: {
    // 分片名切换刷新
    handleSelect() {
      this.$emit('select', this.form.shard)
    },
    // 分片名数组
    async getAliases() {
      let { data } = await this.$api.home.shard()
      if (data) {
        let array = [
          {
            value: null,
            label: '全部'
          }
        ]
        for (let item of data) {
          let obj = {}
          obj.value = item
          obj.label = item
          array.push(obj)
        }
        this.options = array
      }
    },
    rest() {
      this.form.shard = null
      this.form.keepLive = false
      this.form.time = 5000 // 默认5秒
      this.confirm()
    },
    confirm() {
      let _form = {
        shard: this.form.shard,
        keepLive: this.form.keepLive,
        time: this.form.time
      }
      this.$emit('search', _form)
    }
  }
}
</script>
